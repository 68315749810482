import "./Purchase.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth.js";
import { SyncLoader } from "react-spinners";
import { AiOutlineLeft as BackIcon } from "react-icons/ai";
import { Nav } from "../components/home-page/nav-section";
import { Footer } from "../components/home-page/footer-section";
import { getCheckoutUrl, subscriptionIds } from "../functions/stripeCheckout.js";

export default function Purchase() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const is_new = true
  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  async function initiateCheckout() {
    setLoading(true)
    if (is_new) {
      console.log("initiating checkout1 ")
      const priceData = [{
        price: subscriptionIds["onboarding"]["id"],
        quantity: 1
      },
      {
        price: subscriptionIds["license"]["id"],
        quantity: 1
      }]
      let total = subscriptionIds["onboarding"]["price"] + subscriptionIds["license"]["price"]
      let fee = calculateFee(total)
      console.log("fee", fee, total, priceData)
      const checkoutURL = await getCheckoutUrl(priceData, fee)
      console.log("Checkout URL Returned")
      console.log(checkoutURL)
      setLoading(false)
      window.location.href = checkoutURL
    }
  }
  function calculateFee(price) {
    let totalWithFee = (price + .3) / (1 - .029)
    let fee = totalWithFee - price
    return fee
  }

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <div id="outerContainer">
      <Nav />
      <div>
        <div id="purchaseHeader">
          <h1 className="fontheader">Piggyback Joy Starter Pack</h1>
        </div>
      </div>
      <div className="purchaseBody">
        <div id="purchaseDetails">
          <h3 className="fontheader">Access to software and support</h3>
          <ul className="listText">
            <li>Software Program - 1 year subscription for up to 5 verified email addresses within the same business.</li>
            <li>Full Access to our Online Academy</li>
            <li>12 Monthly Individualised 60-minute mentoring/training sessions</li>
            <li>Tech support to keep your Piggyback Joy program running smoothly</li>
          </ul>

          <h3 className="fontheader">Custom Equipment for Piggyback Joy:</h3>
          <ul className="listText">
            <li><strong>Aussie Animal Tangram Book</strong> - Our own playful design to develop advanced visualization skills.</li>
            <li><strong>Wooden Tangram Blocks</strong> - Two hand-colored sets with non-toxic paint, each in a canvas bag.</li>
            <li><strong>Pegboards</strong> - Two sturdy boards (5 and 9 hole) with clear material for easy learning.</li>
            <li><strong>Pegs</strong> - Wooden pegs with easy-grip tops, four colors to match Piggyback games.</li>
            <li><strong>Letter LeapFrog Croaky Charts</strong> - Fun, sturdy charts with silly poems to aid reading development.</li>
            <li><strong>3D Glasses</strong> - Custom designed for Piggyback images and interactive games.</li>
            <li><strong>Sticks and Beads</strong> - Designed for fine motor skill development and visual memory.</li>
            <li><strong>Stick & Straw/Beanbags</strong> - Circus program-based training for eye movement control.</li>
            <li><strong>Ball on String</strong> - Helps reduce startle response and enhances spatial awareness.</li>
            <li><strong>Beads on String</strong> - Builds binocular skills and eye movement control.</li>
            <li><strong>SuperVision Cards</strong> - Advanced visual training with wedge-tailed eagle designs.</li>
          </ul>
        </div>

        <div>
          <img src={"/birdbutterflygraphic.jpg"} style={{ width: '70%' }} />
        </div>
      </div>

      <div>
        <div id="purchaseForm">
          <h2 className="fontheader">Yes! I want to join the Piggyback Joy family</h2>
          <p className="listText"><strong>Sign me up for the first year</strong></p>
          <p className="listText">Onboarding and Training: $3500</p>
          <p className="listText">Yearly Piggyback License Subscription: $1500</p>
          <p className="listText">Total: $5000</p>
          {is_new ? (
            <button onClick={initiateCheckout} type="submit" id="purchaseSubmitButton">
              {loading ? "Loading" : "Purchase"}
            </button>
          ) : (
            <p>
              You are already onboarded. Need more licenses for your practice? Email us at{" "}
              <a href="mailto:info@Piggybackjoy.com">info@Piggybackjoy.com</a>
            </p>
          )}
          <div className="purchaseBodyX">
            <div className="purchaseColumn">
              <h2 className="fontheader">Subscription Renewal</h2>
              <p><strong>Renew for Year Two: $1500</strong></p>
              <ul>
                <li>Software Program - 1 year subscription for up to 5 verified email addresses within the same business.</li>
                <li>Full Access to our Online Academy</li>
                <li>12 Monthly Individualised 60-minute mentoring/training sessions</li>
                <li>Tech support to keep your Piggyback Joy program running smoothly</li>
              </ul>
            </div>

            <div className="purchaseColumn">
              <h2 className="fontheader">Want to test Piggyback Joy before deciding?</h2>
              <p>Email us at <a href="mailto:reframingliteracy@gmail.com">reframingliteracy@gmail.com</a></p>
              <p>Email us to order replacement equipment.</p>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}