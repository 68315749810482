import "./nav-section.css";
import { useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth";

export const Nav = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const onLoginClick = () => {
    if (currentUser) {
      navigate("/activity");
    } else {
      navigate("/login");
    }
  };

  // Function to handle smooth scrolling to a section or navigation to another page and scrolling
  const scrollOrLink = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // If the element exists, scroll to it
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      // If the element does not exist, navigate to the home page with the hash and scroll
      window.location.href = `/#${id}`;
    }
  };


  return (
    <div id="navContainer">
      <div id="nav">
        <button
          onClick={() => navigate("/")}
          style={{ border: "none", background: "none" }}
        >
          <img
            width={126}
            height={31}
            id="piggyBackLogo"
            src="/piggybackLogo.png"
          />
        </button>
        <div id="navLinksContainer">
          <button onClick={onLoginClick} id="loginButton">
            {currentUser ? "Play" : "Login"}
          </button>
          <div id="navLinks">
            <button
              className="navLinkButton"
              onClick={() => navigate("/activity")}
            >
              Activities
            </button>
            <button
              className="navLinkButton"
              onClick={() => scrollOrLink("howToSectionContainer")}
            >
              How To Use
            </button>
            {/* <button onClick={() => navigate("/news")}>News</button> */}
            <button
              className="navLinkButton"
              onClick={() => scrollOrLink("aboutUsSectionContainer")}
            >
              About
            </button>
            <button
              className="navLinkButton"
              onClick={() => navigate("/purchase")}
            >
              Purchase
            </button>
            <button
              className="navLinkButton"
              onClick={() => scrollOrLink("faqSectionContainer")}
            >
              FAQ's
            </button>
            <button
              className="navLinkButton"
              onClick={() => scrollOrLink("contactSectionContainer")}
            >
              Contact
            </button>
            <button
              className="navLinkButton"
              onClick={() => navigate("/account")}
            >
              Account
            </button>
            <button
              className="navLinkButton"
              onClick={() => navigate("/tutorials")}
            >
              Tutorials
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
