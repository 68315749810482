import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from "../../base";

import { AiOutlineLeft as BackIcon } from "react-icons/ai";

import { spellOutDate } from "../../utils/formatDate";

function LicenseInformation(props) {
  return (
    <div className="licenseInformationContainer">
      <p>{props.license}</p>
      <p>
        {props.experationDate &&
          spellOutDate(new Date(props.experationDate.seconds * 1000))}
      </p>
      <p>{props.email}</p>
    </div>
  );
}

export function PatientAccount(props) {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(props.userData.firstName);
  const [lastName, setLastName] = useState(props.userData.lastName);
  const [email, setEmail] = useState(props.userData.email);

  const handleEditInformation = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    const userDocRef = doc(db, "users", props.userData.userID);
    await setDoc(
      userDocRef,
      {
        firstName: firstName,
        lastName: lastName,
      },
      { merge: true },
    );
    const userDocSnap = await getDoc(userDocRef);
    props.setUserData(userDocSnap.data());
    props.setLoading(false);
  };

  return (
    <>
      <div className="accountHeader">
        <h1 style={{ fontFamily: "NexaBold", fontSize: "22px" }}>My Account</h1>
        <div
          onClick={async () => {
            await signOut(auth);
            navigate("/login");
          }}
          id="backButtonContainer"
        >
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Sign Out</p>
        </div>
      </div>
      <form id="providerAccountForm">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          Account Information
        </p>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="firstName">First Name</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              name="firstName"
            />
          </div>
          <div className="accountInputContainer">
            <label htmlFor="lastName">Last Name</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              name="lastName"
            />
          </div>
        </div>
        <div className="accountFormRowContainer">
          <div className="accountInputContainer">
            <label htmlFor="email">Email Adress</label>
            <input
              style={{ width: "600px" }}
              disabled={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              name="email"
            />
          </div>
        </div>
        <div className="editButtonContainer">
          <button
            onClick={handleEditInformation}
            type="submit"
            className="editButton"
          >
            Edit Information
          </button>
        </div>
      </form>
      <div className="licensesContainer">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          License Information
        </p>
        <div className="licensesLabels">
          <p>License</p>
          <p>Experation</p>
          <p>Provider Admin Email</p>
        </div>
        {props.licenseData
          .sort((a, b) => {
            if (a.used === b.used) {
              return 0;
            }
            if (a.used) {
              return -1;
            }
            return 1;
          })
          .map((license) => (
            <LicenseInformation
              key={license.license}
              license={license.license}
              email={license.providerEmail}
              experationDate={license.endDate}
            />
          ))}
      </div>
    </>
  );
}
