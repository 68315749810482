"use client";
//import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {app} from "../base"

export const getCheckoutUrl = async (prices,fee, quantity=5) => {
  let prefix = "https://"
  if(window.location.hostname==="localhost"){
    prefix = "http://"
  }
  const YOUR_DOMAIN = prefix+window.location.host+"/success"

  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const db = getFirestore(app);
  console.log(db)
  const checkoutSessionRef = collection(
    db,
    "users",
    userId,
    "checkout_sessions"
  );
  console.log(checkoutSessionRef, window.location.origin,userId)
  prices.push({//add stripe fee to line items
      price_data: {
        currency: 'usd',
        unit_amount: Math.round(fee * 100),
        product_data: {
          name: 'Fee',
          description: 'Transaction Fee',
        },
      },
      quantity: 1,
  })
  const docRef = await addDoc(checkoutSessionRef, {
    line_items:prices,
    success_url: `${YOUR_DOMAIN}?user_id=${userId}&quantity=${quantity}`,//window.location.origin.split("/"),
    cancel_url: window.location.origin,
    mode : 'subscription',
    payment_method_types : ['card'],
    currency:"usd"
  });
  console.log(docRef)
  
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data()
      console.log(snap.data())
      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async (app) => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  let dataWithUrl
  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    // Add a type to the data
    dataWithUrl = data;
    console.log("Reroute to Stripe portal: ", dataWithUrl.url);
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};



const liveSubscriptionIds ={
  onboarding:{
    id:"price_1Qtos2EIYZealXg63FYXMuUZ",
    price:3500
  },
  license:{
    id:"price_1QzEnvEIYZealXg660eZ0dyl",
    price:1500
  }
}

const testSubscriptionIds = {
  onboarding:{
    id:"price_1QzFJZEIYZealXg6iPBc2a98",
    price:3500
  },
  license:{
    id:"price_1QzFKOEIYZealXg6Yn6ebR8A",
    price:1500
  }
}


export const subscriptionIds = testSubscriptionIds

//IMPORTANT, replace test IDs with live IDS
export const stripePriceIDs = {
  individual_copy:{
    id:"price_1OD772EIYZealXg6x45Nv3ai",
    price: 150
  },
  group_copy:{
    id:"price_1OD76IEIYZealXg6Hgb39b7d",
    price: 135
  },
  blocks:{
    id:"price_1OD734EIYZealXg6aSjq1jsp",
    price:75
  }
}
//Live IDs (only work with live keys)
const stripePriceIDsLive = {
  individual_copy:"price_1LkoquEIYZealXg6LO2oYnoP",
  group_copy:"price_1LkostEIYZealXg6irWynqrk",
  blocks:"price_1LvtYZEIYZealXg6Q0k8zPk0"
}
//Test IDs (only work with test keys)
const stripePriceIDsTest = {
  individual_copy:"price_1OD772EIYZealXg6x45Nv3ai",
  group_copy:"price_1OD76IEIYZealXg6Hgb39b7d",
  blocks:"price_1OD734EIYZealXg6aSjq1jsp"
}
