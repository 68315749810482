import "./Purchase.css";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth.js";
import { SyncLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { writeBatch, doc, arrayUnion, collection, query, where, getDocs} from "firebase/firestore";
import { db } from "../base.js";
import { sendEmail } from "../utils/sendEmail.js";
import { stripePriceIDs } from "../functions/stripeCheckout.js";
export default function Success() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //const [purchaseInitiated, setPurchaseInitiated] = useState(false);
  const [purchaseComplete, setPurchaseComplete] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search)
  //const session_id = queryParameters.get("session_id")
  const userId = queryParameters.get("user_id")
  const copies = queryParameters.get("quantity")

  /*
  async function handleBuyLicenses(checkoutSessionId) {
    try {
      const batch = writeBatch(db);
      const licenses = Array.from({ length: copies }, () => uuidv4());
      console.log("licenses", licenses)
      licenses.forEach((license) => {
        const licenceDoc = doc(collection(db, "licenses"), license);
        batch.set(licenceDoc, {
          providerID: currentUser.uid,
          patientID: "",
          providerEmail: currentUser.email,
          patientEmail: "",
          startDate: "",
          endDate: "",
          license: licenceDoc.id,
          used: false,
        });
        const userDoc = doc(collection(db, "users"), currentUser.uid);
        batch.set(
          userDoc,
          {
            licenseList: arrayUnion(licenceDoc.id),
          },
          { merge: true },
        );
      });
      console.log("adding information to" ,checkoutSessionId)
      const checkoutDoc = doc(collection(db, "users",currentUser.uid,"checkout_sessions"), checkoutSessionId);
        batch.set(
          checkoutDoc,
          {
            associatedLicenses: licenses,
            completed:true,
          }, { merge: true }
        );
      await batch.commit();
      const emailSubject = "Piggyback Licences"
      const emailBody = "Thank you for purchasing licences to use Piggyback.\n\n Each code below can only be used once. They are yours to share with your patients and will expire one year after activation.\n\n" + licenses.join('\n') + "\n\nThank you for choosing our product. We appreciate your trust and we’ll do our best to meet your expectations.\n\nYour Piggyback Team"
      sendEmail(currentUser.email, emailSubject, emailBody);
    } catch (error) {
      console.log(error);
    }
    //navigate("/account");
  }

  async function checkCheckoutSession(){
    setLoading(true)
    const checkoutRefs = collection(db, "users",userId,"checkout_sessions");
    console.log(checkoutRefs)
    const snapshot = await getDocs(query(checkoutRefs,where("sessionId","==",session_id)))
    console.log(snapshot)
    let counter = 0
    let data = {}
    let checkoutId = ""
    snapshot.forEach((doc) => {
      console.log(doc.data)
      //console.log("looping", session_id, doc.data()["sessionId"])
      if(session_id===doc.data()["sessionId"]){
        console.log(doc.id, " => ", doc.data());
        data=doc.data()
        counter+=1
        checkoutId = doc.id
      }
    });
    if (snapshot.empty) {
      alert("No matching documents, please contact support")
      return;
    }  
    else if(counter>1){
      alert("Duplicate session Id, please contact support")
      return;
    }
    else{
      let countConfirmed = false 
      data.line_items.forEach(priceObject =>{
        console.log("priceObject",priceObject.price, [stripePriceIDs.individual_copy.id,stripePriceIDs.group_copy.id])
        if([stripePriceIDs.individual_copy.id,stripePriceIDs.group_copy.id].includes(priceObject.price) && priceObject.quantity===copies){
          countConfirmed = true
        }
      })
      if(!countConfirmed){
        alert("License quantity missmatch, please contact support")
      }
      else if(data.associatedLicenses || data.completed){
        alert("Licenses already generated, check your email, or contact support")
      }
      else{
      console.log("passing checkout item id: ",checkoutId)
      await handleBuyLicenses(checkoutId)
      }
    }
    setPurchaseComplete(true)
    setLoading(false)

  }
  async function handleButtonClickLicense (event){
    event.preventDefault();
    console.log("click")
    //2) Check if user id has a checkout session with the checkout ID
    if(!purchaseInitiated){
    await checkCheckoutSession()
    }
    setPurchaseInitiated(true)
    setPurchaseComplete(true)

  }
  */
  function handleButtonClick(event){
    navigate("/account")
  }

  useEffect(() => {
    //Sanity Checks
    if (!currentUser) {
      navigate("/login");
    }

    if(userId !== currentUser?.uid){
      alert("user ID's not matching, please contact support")
      navigate("/account");
    }
  }, [userId, currentUser, navigate]);

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <>
      <div id="purchaseHeader">
        <div id="purchaseHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
            Thank you for your purchase of {copies} license(s) with Piggyback. 
          </h1>
          {!purchaseComplete && <>
          <p
            className="purchaseParagraph"
          >
           Click below to generate {copies} licenses and to have them sent to your account email: {currentUser.email}
          </p>
          
          <button className="formButton" id="licenseSubmitButton" onClick={e=>
          console.log(e)
            //handleButtonClickLicense(e)
          }> 
            {"Generate Licenses"}
          </button>
          </>}
           {purchaseComplete && <>
            <p
            className="purchaseParagraph"
          >
            We have sent the license codes for {copies} licenses to your account email: {currentUser.email}. 
            They are also available under your account page.
            If you don't see your new licenses on your account page within 2 hours,  message us at efraimcdahl@gmail.com. 
            
          </p>
          <button className="formButton" id="finalizePurchaseButton" onClick={e=>handleButtonClick(e)}>
            {"Okay"}
          </button>
          </>} 
        </div>
        </div>
    </>
  );
}
