import "./Login.css";
import React, { useEffect, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from v6
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, provider, db } from "../base.js";
import { AuthContext } from "../Auth.js";

import { AiOutlineLeft as BackIcon } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { SyncLoader } from "react-spinners";

export const SelectLoginType = (props) => {
  const navigate = useNavigate();

  const handleGoogleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        signInWithPopup(auth, provider);
      } catch (error) {
        alert(error);
      }
    },
    [auth, provider],
  );

  return (
    <>
      <div id="loginHeader">
        <div id="loginHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>Log In</h1>
          <p
            style={{
              fontFamily: "Mulish",
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            Please log in to use piggyback or make purchases.
          </p>
        </div>
        <div onClick={() => navigate("/")} id="backButtonContainer">
          <BackIcon size={20} onClick={() => navigate("/")} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Back</p>
        </div>
      </div>
      <div id="loginBody">
        <button onClick={handleGoogleLogin} id="googleLoginButton">
          <FcGoogle size={25} />
          <p style={{ color: "#434343", marginTop: "15px", fontWeight: "700" }}>
            Sign In with Google
          </p>
        </button>
        <button
          onClick={() => props.setLoginWithEmail(true)}
          id="emailLoginButton"
        >
          <MdEmail color="white" size={25} />
          <p style={{ color: "white", marginTop: "15px", fontWeight: "700" }}>
            Sign In with Email
          </p>
        </button>
        <p
          style={{
            paddingTop: "2rem",
            fontFamily: "Mulish",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          Don't have an account? Please create your account here.
        </p>
      </div>
    </>
  );
};

const EmailLogin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailLogin = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        alert(error);
      }
    },
    [email, password], // Use navigate as a dependency instead of history
  );

  const handleEmailSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        alert(error);
      }
    },
    [email, password], // Use navigate as a dependency instead of history
  );

  return (
    <>
      <div id="loginHeader">
        <div id="loginHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
            Email Log In
          </h1>
          <p
            style={{
              fontFamily: "Mulish",
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            Please login with your email and password.
          </p>
        </div>
        <div
          onClick={() => props.setLoginWithEmail(() => false)}
          id="backButtonContainer"
        >
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Back</p>
        </div>
      </div>
      <form id="loginForm">
        <div className="loginFormRowContainer">
          <div className="loginInputContainer">
            <label for="email">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "300px" }}
              type="email"
              name="email"
            />
          </div>
          <div className="loginInputContainer">
            <label for="password">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: "300px" }}
              type="password"
              name="password"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <button onClick={handleEmailLogin} id="loginWithEmailButton">
            Log In
          </button>
          <button onClick={handleEmailSignUp} id="signUpWithEmailButton">
            Sign up
          </button>
        </div>
      </form>
    </>
  );
};

const SelectAccountType = () => {
  const { currentUser, authUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkIfRecordExisitsForUser = async () => {
      setLoading(true);
      console.log("AuthUserData",authUserData)
      if (authUserData===undefined||authUserData===null) {
        // if we don't have a record for the user, continue as normal
        setLoading(false);
        return;
      }
      const userDocData = authUserData;
      if(userDocData.userType === "admin"){
        navigate("/account")
      }

      if ((userDocData.userType === "patient" && userDocData.license != undefined && userDocData.license != "")) {
        // if user has a license, redirect to activity page
        navigate("/activity");
        return;
      }
      if (userDocData.userType === "provider") {
        console.log("userType is provider")
        const licenseList = userDocData.licenseList;
        if ((userDocData.license==undefined || userDocData.license=="") && (licenseList === undefined || licenseList.length === 0)) {
          alert(
            "You don't own licenses. You will be redirected to the purchase page where you can purchase a license.",
          );
          setLoading(false);
          navigate("/purchase");
          return;
        }
        else if (userDocData.license !== undefined && userDocData.license!=="") {
          setLoading(false);
          navigate("/activity");
          return;
        } 
        else if(userDocData.license==undefined || userDocData.license=="") {
          alert(
            "You don't have an active license. You will be redirected to the account page where you can activate one.",
          );
          setLoading(false);
          navigate("/account");
          return;
        }
        else{
          navigate("/account");
          return;
        }
      }
      setLoading(false);
      return;
    };
    if (currentUser) {
      console.log(currentUser)
      checkIfRecordExisitsForUser();
    }
  }, [currentUser, navigate]);

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <>
      <div id="loginHeader">
        <div id="loginHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
            You're logged in as {currentUser.email}
          </h1>
          <p
            style={{
              fontFamily: "Mulish",
              fontSize: "18px",
              fontWeight: "700",
              paddingTop: "1rem",
            }}
          >
            Please select your account type.
          </p>
        </div>
        <div onClick={() => signOut(auth)} id="backButtonContainer">
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Sign Out</p>
        </div>
      </div>
      <div id="selectAccountTypeButtonsContainer">
        <button
          onClick={() => navigate("/patient-information")}
          id="patientProviderButton"
        >
          Patient
        </button>
        <button
          onClick={() => navigate("/provider-information")}
          id="patientProviderButton"
        >
          Provider
        </button>
      </div>
    </>
  );
};

const LogInScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [loginWithEmail, setLoginWithEmail] = useState(false);

  if (currentUser) {
    return <SelectAccountType />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {loginWithEmail ? (
        <EmailLogin setLoginWithEmail={setLoginWithEmail} />
      ) : (
        <SelectLoginType setLoginWithEmail={setLoginWithEmail} />
      )}
    </div>
  );
};

export default LogInScreen;
